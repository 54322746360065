import scriptsHome from "./scriptsPaginas/home.js";
import scriptsProjetos from "./scriptsPaginas/projetos.js";
import scriptsContato from "./scriptsPaginas/contato.js";
import scriptsSobre from "./scriptsPaginas/sobre.js";

// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js";
import showFormErrors from "./showFormErrors.js";
import menuHamburguer from "./menu.js";
import swiperGeral from "./swiper.js";

// SECTIONS
import salebrandPortfolio from "./salebrand/salebrandPortfolio.js";
import salebrandSobre from "./salebrand/salebrandSobre.js";
import salebrandClientes from "./salebrand/salebrandClientes.js";
import salebrandContato from "./salebrand/salebrandContato.js";
import maisProjetos from './maisProjetos.js'
// PAGES
const pageHome = document.querySelector(".page__home");
const pageProjetos = document.querySelector(".page__projetos");
const pageContato = document.querySelector(".page__contato");
const pageSobre = document.querySelector(".page__sobre");
const AguaSumida = document.querySelector(".page__agua_sumida");
const xangriLa = document.querySelector(".page__xangri_la");
const eixao = document.querySelector(".page__eixao");
const basalto = document.querySelector(".page__basalto");
const vistavino = document.querySelector(".page__vistavino");
const aptAmerica = document.querySelector(".page__apt_america");
const lajeado = document.querySelector(".page__lajeado");
const celeiro = document.querySelector(".page__celeiro");
const ecletica = document.querySelector(".page__ecletica");

// const rui_barbosa = document.querySelector(".page__rui_barbosa");

maisProjetos();
mostrarSenha();
showFormErrors();
menuHamburguer();
swiperGeral();

salebrandPortfolio();
salebrandSobre();
salebrandClientes();
salebrandContato();

// ★ Pages Scopo
if (pageHome) {
  // ★ HOME
  document.body.classList.add("body__home");
  scriptsHome();
}
if (pageProjetos) {
  // ★ HOME
  document.body.classList.add("page__projetos");
  scriptsProjetos();
}
if (pageContato) {
  // ★ HOME
  document.body.classList.add("page__contato");
  scriptsContato();
}
if (pageSobre) {
  // ★ HOME
  document.body.classList.add("page__sobre");
  scriptsSobre();
}
if (AguaSumida) {
  // ★ HOME
  document.body.classList.add("page__agua_sumida");
}

if (xangriLa) {
  // ★ HOME
  document.body.classList.add("page__xangri_la");
}

if (eixao) {
  // ★ HOME
  document.body.classList.add("page__eixao");
}

if (basalto) {
  // ★ HOME
  document.body.classList.add("page__basalto");
}

if (vistavino) {
  // ★ HOME
  document.body.classList.add("page__vistavino");
}
if (aptAmerica) {
  // ★ HOME
  document.body.classList.add("page__apt_america");
}
if (lajeado) {
  // ★ HOME
  document.body.classList.add("page__lajeado");
}

if (celeiro) {
  // ★ HOME
  document.body.classList.add("page__celeiro");
}

if (ecletica) {
  // ★ HOME
  document.body.classList.add("page__ecletica");
}

document.addEventListener("DOMContentLoaded", () => document.body.classList.add("dcl"));
