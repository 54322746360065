export default function swiperGeral() {
  const mySwiper1 = new Swiper(".mySwiper1", {
    slidesPerView: 1.2,
    spaceBetween: 25,
    freeMode: false,
    autoplay: {
      delay: 3000,
    },
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2.2,
      },
      768: {
        spaceBetween: 25,
      },
      1224: {
        spaceBetween: 100,
      },
      1300: {
        slidesPerView: 2.5,
        spaceBetween: 179,
      },
    },
  });

  const mySwiper2 = new Swiper(".mySwiper2", {
    slidesPerView: 1.2,
    spaceBetween: 25,
    freeMode: false,
    autoplay: {
      delay: 3000,
    },
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2.2,
      },
      768: {
        slidesPerView: 2.5,
      },
      1224: {
        slidesPerView: 2.7,
      },
      1300: {
        slidesPerView: 3.5,
      },
    },
  });

  const mySwiper3 = new Swiper(".mySwiper3", {
    slidesPerView: 0.5,
    spaceBetween: 25,
    freeMode: false,
    autoplay: {
      delay: 3000,
    },
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1.5,
      },
    },
  });

  const mySwiper4 = new Swiper(".mySwiper4", {
    // slidesPerView: 2.5,
    slidesPerView: "auto",
    spaceBetween: 25,
    freeMode: false,
    autoplay: {
      delay: 3000,
    },
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
    },

    breakpoints: {
      640: {
        // slidesPerView: 2.2,
        slidesPerView: "auto",
      },
      768: {
        slidesPerView: 2.5,
      },
      1224: {
        slidesPerView: 2.7,
      },
      1300: {
        slidesPerView: 3.2,
      },
    },
  });

  const mySwiper5 = new Swiper(".mySwiper5", {
    slidesPerView: 5,
    spaceBetween: 25,
    freeMode: false,
    autoplay: {
      delay: 3000,
    },
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
    },
    breakpoints: {
      640: {
        // slidesPerView: 1.5,
        slidesPerView: "auto",
      },
    },
  });

  const mySwiper6 = new Swiper(".mySwiper6", {
    // slidesPerView: 2.5,
    slidesPerView: 1,
    spaceBetween: 25,
    freeMode: false,
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
    },
    autoplay: {
      delay: 3000,
    },
    breakpoints: {
      640: {
        slidesPerView: 2.5,
      },
    },
  });

  const swippers3x = document.querySelectorAll(".mySwiper3x");

  swippers3x?.forEach((swiper) =>
    swiper.insertAdjacentHTML(
      "beforeend",
      `   <div class="swiper-btns">
                <div class="swiper-button-next">
                    <svg class=''>
                        <use xlink:href='#icon_seta'></use>
                    </svg>
                </div>
                <div class="swiper-button-prev">
                    <svg class=''>
                        <use xlink:href='#icon_seta'></use>
                    </svg>
                </div>
            </div>`
    )
  );

  swippers3x?.forEach((swiper) => {
    const mySwiper3x = new Swiper(swiper, {
      slidesPerView: "auto",
      spaceBetween: 25,
      freeMode: false,
      // centeredSlides:true,
      // initialSlide:0,
      navigation: {
        nextEl: swiper?.querySelector(".swiper-button-next"),
        prevEl: swiper?.querySelector(".swiper-button-prev"),
      },
      mousewheel: {
        forceToAxis: true,
        releaseOnEdges: true,
      },

      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        // 640: {
        //   slidesPerView: 2.2,
        // },
        // 768: {
        //     spaceBetween: 25,
        // },
        // 1224: {
        //     spaceBetween: 100,
        // },
        // 1300: {
        //     slidesPerView: 2.5,
        //     spaceBetween: 179,
        // },
      },
    });
  });

  const mySwiper3x = new Swiper(".mySwiper4x", {
    slidesPerView: "auto",
    spaceBetween: 25,
    freeMode: false,
    updateOnWindowResize: true,
    simulateTouch: true,
    // centeredSlides:true,
    // initialSlide:0,
    // navigation: {
    //   nextEl: swiper?.querySelector(".swiper-button-next"),
    //   prevEl: swiper?.querySelector(".swiper-button-prev"),
    // },
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
      // sensitivity: 0.1,
    },

    autoplay: {
      delay: 3000,
    },
    breakpoints: {
      // 640: {
      //   slidesPerView: 2.2,
      // },
      // 768: {
      //     spaceBetween: 25,
      // },
      // 1224: {
      //     spaceBetween: 100,
      // },
      // 1300: {
      //     slidesPerView: 2.5,
      //     spaceBetween: 179,
      // },
    },
  });

  // debugger
}
