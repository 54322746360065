export default function scriptsProjetos() {
  const swiperInit = () =>
    new Swiper(".mySwiper", {
      slidesPerView: 2,
      spaceBetween: 25,
      observer: true,
      updateOnWindowResize: true,
      mousewheel: {
        forceToAxis: true,
        releaseOnEdges: true,
      },
      grid: {
        rows: 2,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        955: {
          slidesPerView: 3,
        },
        1224: {
          slidesPerView: 4,
        },
        1650: {
          slidesPerView: 5,
        },
        2150: {
          slidesPerView: 6,
        },
      },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

  var swiper = swiperInit();

  window.swiperProjetos = swiper;
  window.swiperInit = swiperInit;

  window.addEventListener("resize", () => {
    swiper.destroy();
    setTimeout(() => swiperInit(), 100);
  });
  const mobile = window.matchMedia("(max-width:780px)").matches
  if(mobile){
    const menuToggle = document.querySelector(".menu-toggle")
    const menu = document.querySelector(".menu")

    if(menuToggle){
      menuToggle.classList.remove('open');
      menu.classList.remove('open');

    }
  };
}
