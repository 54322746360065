export default function maisProjetos() {
  const section = document.querySelector("[js-mais-projetos]");
  if (!section) return;

  const slides = section.querySelectorAll(".swiper-slide .projeto-wrapper");

  slides.forEach((slide) => {
    const path = window.location.pathname;
    if (slide.href.includes(path)) {
      slide.closest(".swiper-slide").remove();
    }
  });
}
