export default function scriptsHome() {
  // 'colocar scripts das respectivas paginas dentro de -scriptsPaginas-  e criar o arquivo exemplo: home.js - contato.js  --- e importar no main e executar em sua respectiva pagina '

  function swiperInit() {
    const swiperBanner = new Swiper(".swiper-banner", {
      spaceBetween: 30,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 4000,
      },

      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

      on: {
        init: function (e) {
          const { slides, realIndex, previousIndex } = e;
          const el = slides[realIndex].querySelector("img");
          anime(el, 80);
        },
      },
    });

    function anime(el, x, els) {
      const isMobile = window.matchMedia("(min-width: 768px)").matches;
      gsap.set(el, {
        scale: 1.15,
        x: isMobile ? x : 0,
      });
      // if (els) els.forEach(i => gsap.set(i, {}))
      gsap.timeline().to(el, {
        clearProps: "all",
        scale: 1.1,
        x: 0,
        duration: 15,
        ease: "power1.out",
        overwrite: true,
        //   transformOrigin: "right left",
      });
    }

    swiperBanner.on("activeIndexChange", function (e) {
      const { slides, realIndex, previousIndex } = e;

      let x = 80;

      if (Math.random() > 0.5) x = 80;
      else x = -80;

      if (previousIndex > realIndex) {
        x = -80;
      }
      const el = slides[realIndex].querySelector("img");
      console.log("slide changed", { e });
      anime(el, x, slides);
    });
  }

  function addClass() {
    document.body.classList.add("animate-end");
  }

  ///Animation
  const banner = document.querySelector("#banner"),
    logoAnimada = document.querySelector("#banner .logo-animada"),
    bannerWrapper = document.querySelector(".swiper-wrapper-1"),
    menuBtn = document.querySelector(".hamburger-menu"),
    logoBranca = document.querySelector(".img-branco.logo-img"),
    menuContato = document.querySelector("#banner .btn-show"),
    logoArquitetos = document.querySelector(".arquitetos");

  const mobile = window.matchMedia("(max-width: 768px)").matches;

  const y = 200,
    inset0 = "inset(0% 0% 0% 0%)",
    clipBanner = mobile ? "inset(20px 20px 20px 20px)" : "inset(50px 50px 50px 50px)";

  const tl = gsap
    .timeline({ paused: true }) //
    .set(banner, { clipPath: inset0 })
    .set(menuBtn, { opacity: 0 })
    .set(menuContato, { opacity: 0 })
    .set(logoBranca, { opacity: 0 })
    .set(logoArquitetos, { y })
    .set([logoArquitetos, logoBranca], { opacity: 0 })
    // .set(bannerWrapper, {yPercent:100})
    .set(bannerWrapper, { display: "flex" })
    .to(logoAnimada, { x: 0, y: 0, ease: "sine.out", duration: 0.45 }, "+=3.1")
    .call(swiperInit)
    .to(banner, { clipPath: clipBanner, duration: 0.6, ease: "expo.inOut" }, "-=0.2")
    .to(bannerWrapper, { y: 0, scale: 1, ease: "expo.inOut", duration: 2.6 }, "juntos-=1")
    .set([logoArquitetos], { opacity: 1 }, "juntos+=0.1")
    .to(logoBranca, { opacity: 1, duration: 0.3 }, "juntos+=0.1")
    .to(logoArquitetos, { y: 0, ease: "expo.out", duration: 2.3 }, "juntos+=0.3")
    .to(menuBtn, { opacity: 1, duration: 1, ease: "expo.out" }, "juntos+=1")
    .to(menuContato, { opacity: 1, duration: 1, ease: "expo.out" }, "juntos+=1")
    .set(logoAnimada, { display: "none" }, mobile ? "juntos" : "juntos+=1")
    .call(addClass);

  tl.play();
  window.tl = tl;
}

/* 

  const tl = gsap
    .timeline({ paused: true }) //
    // .call(, null)

    .set(banner, { clipPath: "inset(0% 0% 0% 0%)" })
    .set(menuBtn, { opacity: 0 })
    .set(logoAnimada, { clipPath: "inset(0% 0% 0% 0%)" })
    // .set(bannerWrapper, { yPercent: 100 })
    .to(logoAnimada, { duration: 0.45, x: 0, y: 0, ease: "sine.out" }, "+=3.1")
    .to(banner, { clipPath: "inset(50px 50px 50px 50px)", duration: 0.2 }, "+=0.1")
    .call(swiperInit)
    .set(bannerWrapper, { opacity: 1 })
    // .to(fundo, { yPercent: -100, ease: "expo.inOut", duration: 1.5 }, "juntos-=0.3")
    .to(bannerWrapper, { y: 0, ease: "expo.inOut", duration: 2.2 }, "juntos-=0.3")
    // .to(
    //   logoAnimada,
    //   { clipPath: "inset(0% 0% 100% 0%)", duration: 0.25, ease: "linear" },
    //   "juntos+=0.16"
    // )
    .to(menuBtn, { opacity: 1 }, "juntos+=0.4");
  // .call(hideElements);

  // tl.timeScale(0.5).play();
  tl.play();
  // .to(menuBtn, { opacity: 1 }, "juntos+=0.4");

  //falta ocultar o menu e contato dps aparecer
  window.tl = tl;
*/
