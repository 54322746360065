export default function menuHamburguer() {
  const menuToggle = document.querySelector(".menu-toggle");
  const menu = document.querySelector(".menu");
  const imgAzul = document.querySelectorAll(".img-branco");
  const body = document.body;

  const mobile = window.matchMedia("(max-width:780px)").matches;

  if (menuToggle) {
    menuToggle.addEventListener("click", () => {
      menuToggle.classList.toggle("open");
      menu.classList.toggle("open");
      body.classList.toggle("open-menu");
      imgAzul.forEach((item) => item.classList.toggle("open"));
    });
  }



  if (mobile) {
    const menuToggle = document.querySelector(".menu-toggle");
    const menu = document.querySelector(".menu");

    if (menuToggle && menu) {
      menuToggle.classList.remove("open");
      menu.classList.remove("open");
    }
  }
}
